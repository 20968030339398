import React, { Component } from "react";
class Loading extends Component {
  state = {};
  render() {
    return (
      <div className="loadin-main-container">
        <h3>EMPRESS</h3>
        <h5>STUDIO</h5>
      </div>
    );
  }
}

export default Loading;
